* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.currency {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    background-color: #FFF;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.07);
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, #1942DF, #3EC878) 1;
    width: 100%;
    height: 60px;
    left: 0px;
    top: 106px;

}


#desktopflags {
    display: none;
}

.flags {
    display: flex;
    margin-top: 10px;
    @media screen and (max-width: 820px) {
        margin-top: 2px;
      }
}

.flags p {
    /* margin-bottom: 0; */
}

.currencyContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 75%;
    margin: 0 auto;
}

.flags img {

    position: relative;
    top: -1px;
    left: -5px;
}

@media screen and (max-width: 960px) {}

@media screen and (max-width: 755px) {
    .flags p {
        font-size: 10px;
    }
}

@media screen and (max-width: 630px) {
    #desktopflags {
        display: block;
    }

    .flags {
        display: flex;
        flex-direction: column;
    }

    .flags img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 117px;
        height: 19px;
    }

    .currencyContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        width: 100%;
        align-items: center;
        margin-top: 10px;
        margin-left: 20px;
        overflow-x: scroll;
    }
}

::-webkit-scrollbar {
    display: none;
}