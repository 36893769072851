* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat";
}

.trust {
  width: 100%;
  margin: 0 auto;
  height: 700px;
}

.trust-container {
  width: 100%;
  margin: 0 auto;
}

.trust-text h1 {
  padding-bottom: 40px;
  color: #1942df;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 73px;

  color: #1942df;
}

.trust-text h5 {
  padding-top: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  color: #231f20;
}

.trust-text .text2 {
  padding: 25px 180px 0 0;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
}

.trust-text {
  float: left;
  padding-right: 30px;
  width: 60%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.trust-text p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;

  color: #231f20;
  width: 660px;
}

.trust-text .license {
  color: #1942df;
}

.trust-img {
  float: right;
  width: 35%;
  height: 356px;
  display: flex;
  margin-top: 130px;
}

.approval {
  position: absolute;
  right: 20px;
  width: 459px;
  height: 365px;
}

.thumbup {
  position: relative;
  right: 50px;
  width: 259px;
  height: 365px;
}

img .trust-img2 {
  margin: 290px;
}

.trust-btn {
  color: white;
  background: #0abe3b;
  background: #0abe3b;
  box-shadow: -3px 4px 8px rgba(78, 169, 43, 0.4);
  border-radius: 50px;
  padding: 11px 17px;
  font-weight: 600;
  width: 152px;
  height: 38px;
  text-align: center;
  font-size: 14px;
  line-height: 17px;
  margin-top: 50px;
}

.trust-btn a {
  width: 86px;
  height: 16px;
}

a:-webkit-any-link {
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .trust-text h1 {
    padding-bottom: 21px;
    font-size: 25px;
    line-height: 30px;
    margin-top: -24px;
  }

  .trust-text h5 {
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
  }

  .trust-text .text2 {
    padding: 25px 0 0 0;
    font-weight: 500;
    font-size: 19px;
    line-height: 23px;
  }

  .trust-text {
    float: left;
    padding-right: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .trust-text p {
    font-size: 16px;
    line-height: 21px;
  }

  .trust-text .license {
    color: #1942df;
  }

  .trust-img {
    float: none;
    width: 100%;
    position: relative;
    flex-direction: row-reverse;
    bottom: 135px;
  }

  .approval {
    position: absolute;
    right: 20px;
    width: 294px;
    height: 294px;
  }

  .thumbup {
    position: absolute;
    left: 0;
    width: 166.99px;
    z-index: 1;
    bottom: 50px;
    height: 235.33px;
  }

  img .trust-img2 {
    margin: 290px;
  }

  .trust-btn {
    color: white;
    background: #0abe3b;
    box-shadow: -3px 4px 8px rgba(78, 169, 43, 0.4);
    border-radius: 50px;
    padding: 10px 16px;
    font-weight: 600;
    width: 162px;
    height: 38px;
    font-size: 14px;
    line-height: 17px;
    margin-top: 190px;
    margin-left: 89px;
    position: relative;
    bottom: -200px;
  }

  .trust-btn a {
    width: 86px;
    height: 16px;
  }

  a:-webkit-any-link {
    text-decoration: none;
  }

  .trust-text p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 137%;
    /* or 22px */
    color: #231f20;
    width: 335px;
  }
}
@media screen and (max-width: 400px) {
  .trust-btn {
    margin-left: 95px;
  }
}
.trust-btn:hover{
  color: #fff;
}