* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat";
}
.consultation {
  width: 100%;
  height: 100vh;
  a:-webkit-any-link {
    text-decoration: none;
  }
  .consultation-btn {
    margin-top: 10px;
    padding: 12px;
    text-align: center;
  }
}
.consultation-container {
  width: 85%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

.consultation-btn {
  color: white;
  background: #0abe3b;
  background: #0abe3b;
  box-shadow: -3px 4px 8px rgba(78, 169, 43, 0.4);
  border-radius: 50px;
  padding: 12px 34px;
  font-weight: 600;
  width: 152px;
  height: 38px;
  font-size: 14px;
  line-height: 17px;
  margin-top: 50px;
}
.consultation-btn:hover {
  color: white;
}

.consultation-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 65px;
}
.consulatation-right {
  position: relative;
  right: 499px;
  top: 137px;
}
.consultation-container {
  width: 85%;
  margin: 20px 0 0 130px;
}

.consultation-left h1 {
  width: 1105px;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 40px;
  margin-bottom: 90px;

  color: #1942df;
}
.consultation p {
  width: 799px;
  margin-bottom: 40px;
}

.consultation-img {
  display: inline-block;
  width: 600px;
  height: 590px;
  margin-left: 50px;
}

@media screen and (max-width: 600px) {
  .consultation {
    width: 100%;
    height: 1240px;
  }
  .consultation-left {
    margin-top: 30px;
  }
  .consultation-container {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  .consultation-left h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 137%;
    width: 352px;
    margin-bottom: 30px;
    /* or 34px */
    color: #1942df;
  }
  .consultation-left p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 160.9%;
    width: 352px;
    /* or 26px */
    color: #000000;
  }
  .consulatation-right {
    position: relative;
    display: flex;
    right: 5px;
    top: 65px;
  }
  .consultation-img {
    width: 543px;
    height: 395px;
  }
}
@media screen and (max-width: 470px) {
  .consultation-img {
    width: 375px;
    height: 300px;
  }
}
