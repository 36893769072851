.productspage {
  height: 100%;
}

.productspage h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 73px;

  color: #1942df;
  margin-bottom: 30px;
}

.products-container {
  width: 100%;
  height: 600px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  // margin-bottom: 61px !important;
}

.products {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 30px;
  background: #ffffff;
  transition: all ease 0.5s;
  width: 257px;
  // padding: 20px;
  box-shadow: 0px 0px 20px rgba(51, 96, 231, 0.2);
  border-radius: 15px;
  margin: 10px;
  cursor: pointer;
  height: 360px;
  overflow: hidden;
  .product-title {
    height: 120px;
    margin-bottom: 40px;
  }
  .product-img {
    min-height: 170px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &:hover {
    height: 410px;
  }
}

// .btn-products {
//   display: none;
// }

.btn-products {
  display: block;
  padding: 8px 29px;
  color: white;
  transition: all ease 0.5s;
  background: #ff5a3a;
  box-shadow: -3px 4px 8px rgba(255, 90, 58, 0.4);
  border-radius: 50px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: none;
}

.btn-products {
  color: white;
  background: #ff5a3a;
  box-shadow: -3px 4px 8px rgba(255, 90, 58, 0.4);
  border-radius: 50px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: none;
}
.btn-products:hover {
  color: #fff;
}

.products h4 {
  text-align: center;
  margin: 20px 0;
}

.products p {
  text-align: center;
  margin-bottom: 15px;
}

@media screen and (max-width: 900px) {
  .products-container {
    display: flex;
    /* width: 450px; */
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    /* padding: 30px; */
  }
}

@media screen and (max-width: 600px) {
  .productspage h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #1942df;
  }
  .products-container {
    height: 100%;
  }
  .products {
    overflow: inherit;
    height: 100%;
  }
}

.img.factoring {
  width: 145px;
  height: 300px;
}
.producttext {
  width: 700px;
  margin-top: -126px;
}
@media screen and (max-width: 600px) {
  .producttext {
    width: 300px;
    margin-top: -45px;
    margin-bottom: 40px;
  }
}
