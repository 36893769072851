.partners {
    

    &__slider-item img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-repeat: no-repeat;

    }

    ul li::before {
        display: none;
    }

    .slick-dots {
        bottom: 10px;
    }

    .slick-dots li {
        width: 5px;
        height: 5px;
    }
    
    .slick-dots li button {
        padding: 0;
        width: 100%;
        height: 100%;
    }

    .slick-dots li button:before {
        position: static;
        display: block;
        width: 100%;
        height: 100%;
    }

    .arrow-left {
        position: absolute;
        z-index: 1;
        transform: translateY(-50%);
        top: 50%;
        left: 20px;
        background: #ffffff;

        background: #ffffff;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .arrow-right {
        position: absolute;
        z-index: 1;
        transform: translateY(-50%);
        top: 50%;
        right: 20px;

        background: #ffffff;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .partners__slider-item {
        height: 400px;
    }

    @media(max-width: 850px) {
        .arrow-right,
        .arrow-left {
            height: 25px;
            width: 25px;
        }
    }
}

.partners__company {
    display: flex;
    flex-wrap: wrap;

    &-details {
        flex: 1;
    }

    &-text {
        font-weight: 400;
        font-size: 23px;
        line-height: 28px;
        color: #000000;
        margin-bottom: 30px;

        @media (max-width: 426px) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    &-img {
        width: 500px;
        height: 500px;
    }

    &-img img {
        width: 100%;
        height: auto;
    }
}

.partners__products {

    &-list {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        align-items: center;

        @media (max-width: 1050px) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media (max-width: 850px) {
            grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 600px) {
            grid-template-columns: 1fr;
        }
    }

    .products {
        height: max-content;
        width: auto;
    }

    .products:hover {
        transform: translateY(-20px);
    }
}

.section-title {
    font-weight: 700;
    font-size: 60px;
    line-height: 73px;
    color: #1942df;
    margin-bottom: 60px;
    
    @media (max-width: 426px) {
        font-size: 30px;
        line-height: 137%;
    }
}

.partners__structure {
    // max-width: 1100px;

    ul, ol, li {
        padding: 0;
        margin: 0;
    }

    &-container {
        max-width: 900px;
        padding: 0 20px;
        margin: 0 auto;
    }

    &-content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    &-list {
        position: relative;
    }

    &-list.first::after {
        content: "";
        height: calc(100% - 144px);
        width: 2px;
        position: absolute;
        right: -2px;
        top: 72px;
        background: #000000;
    }

    &-item:not(.empty) {
        background: #ffffff;
        box-shadow: 0px 0px 20px rgba(51, 96, 231, 0.2);
        border-radius: 15px;
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 15px 20px;
    }

    &-title {
        font-weight: 500;
        font-size: 1.5rem;
    }

    &-text {
        text-align: center;
        margin-top: 15px;
    }

    &-item.empty {
        width: 300px;
        height: 140px;
    }

    &-item.first:before {
        content: "";
        display: block;
        position: absolute;
        background: #000000;
        width: calc(100% - 300px);
        height: 2px;
        right: 0;
    }

    &-item.second {
        margin-left: auto;
    }
    
    &-item.second:before {
        content: "";
        display: block;
        position: absolute;
        background: #000000;
        width: calc(100% - 300px);
        height: 2px;
        left: 0;
        transform: translateX(17px);
    }

    @media (max-width: 680px) {
        &-content {
            grid-template-columns: repeat(1, 1fr);
        }

        &-list.first::after {
            height: calc(100% - 69px);
        }

        &-list.second::after {
            content: "";
            height: calc(100% - 72px);
            width: 2px;
            position: absolute;
            right: -2px;
            top: 0;
            background: #000000;
        }

        &-item.second {
            margin-left: 0;
            margin-right: auto;
        }

        &-item.second:before {
            right: 0;
            left: inherit;
            transform: translateX(0);
        }
    }
}

.partners__commands {

    &-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        place-items: center;
        gap: 20px;
        padding: 0;
        overflow: auto;
    }

    &-item {
        background: #ffffff;
        box-shadow: 0px 0px 20px rgba(51, 96, 231, 0.2);
        width: 300px;
        height: 400px;
        border-radius: 15px;
        position: relative;
    }

    &-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 15px;
        background: rgba(0, 0, 0, 0.2);
        padding: 10px 20px;
        opacity: 0;
        visibility: hidden;
        transition: all .2s ease-in-out;
    }

    
    &-item:hover &-info {
        display: flex;
        opacity: 1;
        visibility: visible;
    }

    &-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 15px;
        box-shadow: 0px 0px 20px rgba(51, 96, 231, 0.2);
    }

    &-name {
        color: #ffffff;
    }

    &-spec {
        color: #ffffff;
        font-weight: 500;
    }

    &-text {
        font-size: 16px;
        color: #ffffff;
        margin-top: 10px;
        text-align: center;
        line-height: 22px;
        letter-spacing: 0.6px;
    }
}

.partners__getpdf {
    margin-top: 50px;

    &-link {
        display: block;
        color: white;
        background: #0abe3b;
        box-shadow: -3px 4px 8px rgba(78, 169, 43, 0.4);
        border-radius: 50px;
        padding: 11px 17px;
        font-weight: 600;
        width: 152px;
        height: 38px;
        text-align: center;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 50px;
        text-decoration: none;
        cursor: pointer;
    }

    &-link:hover {
        color: white;
    }
}

.loader-spinner {
    height: 80vh;
    background: rgba(0, 0, 0, 0.2);
}